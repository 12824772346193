.carousel .thumbs-wrapper {
    display: none; /* This will remove the thumbnails */
  }
  .control-dots {
    bottom: 10px; /* Adjust the position if needed */
  }
  
  .control-dots .dot {
    background-color: #ccc; /* Default inactive color */
    opacity: 1; /* Ensure visibility */
    transition: background-color 0.3s; /* Smooth transition for color change */
    border-radius: 50%; /* Make sure dots are circular */
  }
  
  .control-dots .dot.selected {
    background-color: #018B95; /* Active color */
  }
  /* Hide the image count (1 of 2) */
.carousel .carousel-status {
    display: none; /* This will remove the status text */
  }